<template>
    <!-- HORIZONTAL LAYOUT -->
    <vx-card title="View Bill of Material Document">
        <div class="vx-row mb-6" style="width: 50%">
            <vs-button class="ml-4 mt-2" color="danger" icon-pack="feather" icon="icon-arrow-left"
                @click="handleBack()">Back</vs-button>
        </div>
        <div class="vx-row">
            <div class="vx-col sm:w-1/2 w-full mb-base">
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/4 w-full">
                        <span>BoM ID </span>
                    </div>
                    <div class="vx-col sm:w-2/5 w-full">
                        <vs-input class="w-full" v-model="code" disabled />
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/4 w-full">
                        <span>BoM Description</span>
                    </div>
                    <div class="vx-col sm:w-2/5 w-full">
                        <vs-input class="w-full" v-model="description" disabled />
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/4 w-full">
                        <span>Purpose</span>
                    </div>
                    <div class="vx-col sm:w-2/5 w-full">
                        <multiselect class="selectExample" v-model="selectedPurpose" :options="optionPurpose"
                            :multiple="false" :filterable="true" :allow-empty="true" :group-select="true"
                            :max-height="100" :limit="3" :internal-search="false" placeholder="Type to search"
                            :searchable="true" track-by="value" label="name" :disabled="true" />
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/4 w-full">
                        <span>Instruction</span>
                    </div>
                    <div class="vx-col sm:w-3/4 w-full">
                        <vs-textarea v-model="instruction" class="w-full" rows="3" name="instruction"
                            disabled></vs-textarea>
                    </div>
                </div>

                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/4 w-full">
                        <label>Attachment
                            <small style="color: red">(only: jpg, jpeg, pdf, doc, docx, png, xlsx, xls)</small></label>
                    </div>
                    <div class="vx-col sm:w-3/4 w-full">
                        <div class="vx-col w-1/2">
                            <!-- only accept "jpg", "jpeg", "pdf", "doc", "docx", "png" , "xlsx", "xls"-->
                            <input id="fileInput" name="file" class="w-full inputx" type="file" ref="file"
                                multiple="multiple" accept=".jpg, .jpeg, .png, .pdf, .doc, .docx, .xlsx, .xls" />
                        </div>
                    </div>
                </div>
                <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
                    <div class="vx-col sm:w-1/4 w-full">
                        <span></span>
                    </div>
                </div>
                <!-- display: block -->
                <vs-divider style="width: 60%; margin-left: 30%">
                    List Attachment
                </vs-divider>
                <div class="vx-row mb-3 mt-6" style="width: 60%; margin-left: 30%">
                    <table class="vs-table vs-table--tbody-table">
                        <template>
                            <tr class="tr-values vs-table--tr tr-table-state-null selected" v-bind:key="tr.NameFile"
                                v-for="tr in fileAttachment">
                                <td class="td vs-table--td">{{ tr.NameFile }}</td>
                                <td class="td vs-table--td">
                                    <vx-tooltip text="Show" v-if="tr.PathFile != ''">
                                        <vs-button type="line" icon-pack="feather" icon="icon-eye"
                                            @click.stop="handleShowAttachment(tr)" />
                                    </vx-tooltip>
                                </td>
                            </tr>
                        </template>
                    </table>
                </div>
            </div>
        </div>
        <h3><b>Parent</b></h3>
        <hr />
        <br />
        <div class="vx-row">
            <div class="vx-col sm:w-1/1 w-full mb-base m-10">
                <table width="100%" class="vs-table vs-table--tbody-table">
                    <thead class="vs-table--thead">
                        <tr>
                            <th width="20%">SKU Code</th>
                            <th width="20%">SKU Name</th>
                            <th width="20%">HU</th>
                            <th width="20%">Qty [SKU]</th>
                            <th width="10%">Qty UOM</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="td vs-table--td" style="padding: 5px">
                                <multiselect class="selectExample" v-model="itemParent.selected_items"
                                    :options="optionItem" :multiple="false" :allow-empty="false" :group-select="false"
                                    :max-height="160" :limit="4" placeholder=" Type to search" track-by="item_id"
                                    label="sku_code" @search-change="onSearchItems($event, true)" :disabled="true" />
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <span>
                                    {{
                                        itemParent.selected_items
                                            ? itemParent.selected_items.item_name
                                            : ""
                                    }}
                                </span>
                            </td>

                            <td class="td vs-table--td" style="padding-right: 5px">
                                <multiselect v-if="itemParent.unit" class="selectExample"
                                    :options="itemParent.optionUnit" :multiple="false" :allow-empty="false"
                                    :group-select="false" :max-height="160" :limit="10" placeholder=" Type to search"
                                    v-model="itemParent.unit" :disabled="true" />
                            </td>

                            <td class="td vs-table--td" style="padding-right: 10px">
                                <vs-input class="w-full" type="number" v-model="itemParent.qty" :min="1" disabled />
                            </td>

                            <td class="td vs-table--td align-top" style="padding: 5px">
                                <vs-input class="w-full" type="number" v-model="itemParent.qty_uom" disabled></vs-input>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <h3><b>Child</b></h3>
        <hr />
        <br />

        <div class="vx-row">
            <div class="vx-col sm:w-1/1 w-full mb-base m-10">
                <table width="100%" class="vs-table vs-table--tbody-table">
                    <thead class="vs-table--thead">
                        <tr>
                            <th width="20%">SKU Code</th>
                            <th width="30%">SKU Name</th>
                            <th width="20%">HU</th>
                            <th width="20%">Qty [SKU]</th>
                            <th width="10%">Qty UOM</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(child, index) in itemLines" :key="index" style="padding-bottom: 5px">
                            <td class="td vs-table--td" style="padding: 5px">
                                <multiselect class="selectExample" v-model="itemLines[index].selected_items"
                                    :options="optionItem" :multiple="false" :allow-empty="false" :group-select="false"
                                    :max-height="160" :limit="4" placeholder=" Type to search" track-by="item_id"
                                    label="sku_code" @search-change="onSearchItems($event, true)" :disabled="true" />
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <span>
                                    {{
                                        itemLines[index].selected_items
                                            ? itemLines[index].selected_items.item_name
                                            : ""
                                    }}
                                </span>
                            </td>

                            <td class="td vs-table--td" style="padding-right: 5px">
                                <multiselect v-if="itemLines[index].unit" class="selectExample"
                                    v-model="itemLines[index].unit" :options="itemLines[index].optionUnit"
                                    :multiple="false" :allow-empty="false" :group-select="false" :max-height="160"
                                    :limit="10" placeholder=" Type to search" :disabled="true" />
                            </td>

                            <td class="td vs-table--td" style="padding-right: 10px">
                                <vs-input class="w-full" type="number" v-model="itemLines[index].qty" :min="1"
                                    disabled />
                            </td>

                            <td class="td vs-table--td" style="padding-right: 10px">
                                <vs-input class="w-full" type="number" v-model="itemLines[index].qty_uom" :min="1"
                                    disabled />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </vx-card>
</template>

<script>
import moment from "moment";
export default {
    components: {},
    async mounted() {
        this.getData(this.$route.params.id);
        // this.getOptionItemAvailable();
    },
    computed: {
        setPage: {
            get() {
                return 1;
            },
            set(val) {
                this.handleChangePage(val);
            },
        },
    },
    filters: {
        formatDate(value) {
            if (value) {
                return moment(String(value)).format("DD MMM YYYY");
            }
        },
    },
    watch: {},
    data: () => ({
        optionItem: [],
        itemLines: [
            {
                id: 0,
                optionItem: [],
                selected_items: null,
                sku_code: null,
                item_name: null,
                label: null,
                optionUnit: [],
                unit: null,
                qty: 0,
                qty_uom: 0,
            },
        ],
        itemParent: {
            selected_items: null,
            option_item_unit: [],
            selected_item_unit: null,
            qty: 1,
            price: 0,
            qty_uom: 0,
        },
        optionPurpose: [
            {
                value: "0",
                name: "SKU-ID Change",
            },
            {
                value: "1",
                name: "Repackaging",
            },
        ],
        selectedPurpose: null,
        start: 1,
        end: 0,
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        code: "",
        description: "",
        instruction: "",
        attachment: "",
        fileAttachment: [],
        IDAttachment: [],
        tommorow: moment().add(1, "days").format("YYYY-MM-DD"),
        IDs: null,
        isDisabled: true,
    }),
    methods: {
        handleBack() {
            this.$router.push({
                name: "bill-of-material",
            });
        },
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        containsKey(obj, key) {
            return Object.keys(obj).includes(key);
        },

        // getOptionItemAvailable(index) {
        //   // this.isActive = page;
        //   this.$vs.loading();
        //   this.$http
        //     .get("api/wms/v1/item-bill-of-material/items", {
        //       params: {
        //         length: 100,
        //         page: this.page,
        //         search: this.search,
        //         order: this.order,
        //         sort: this.sort,
        //       },
        //     })
        //     .then((resp) => {
        //       if (resp.code == 200) {
        //         this.optionItem = resp.data.records;
        //         console.log("optionItem", this.optionItem);
        //         console.log("itemLines", this.itemLines);
        //         this.optionItem = this.optionItem.filter((el) => {
        //           let cek = this.itemLines.filter((el2) => {
        //             if (el2.selected_items) {
        //               if (el2.selected_items.item_id == el.item_id) {
        //                 return true;
        //               }
        //             }
        //             return false;
        //           });
        //           if (cek.length > 0) {
        //             return false;
        //           }
        //           return true;
        //         });
        //         this.$vs.loading.close();
        //       } else {
        //         this.$vs.notify({
        //           title: "Error",
        //           text: "Failed to get Item Available",
        //           color: "danger",
        //           position: "top-right",
        //           iconPack: "feather",
        //           icon: "icon-check",
        //         });
        //       }
        //       this.$vs.loading.close();
        //     });
        // },
        addRow(index) {
            if (!this.itemLines[index].selected_items) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select Item Line first",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check",
                });
                return true;
            } else {
                this.itemLines.push({
                    option_items: [],
                    selected_items: null,
                    option_item_unit: [],
                });

                this.getOptionItemAvailable(this.itemLines.length);
            }
        },
        removeRow(index) {
            this.itemLines.splice(index, 1);
        },
        onChangeItems(val, index) {
            let cek = this.itemLines.filter((el, i) => {
                if (el.selected_items) {
                    if (el.selected_items.id == val.id && i != index) {
                        return true;
                    }
                }
                return false;
            });

            if (cek.length > 0) {
                this.itemLines[index].selected_items = null;
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Sales already add",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }
            // console.log("val", val);

            this.itemLines[index].selected_items = val;
        },
        onSearchItems(search) {
            this.search = search;
            this.page = 1;
            this.getOptionItemAvailable();
        },
        getData(id) {
            this.$vs.loading();
            this.$http
                .get(`api/wms/v1/item-bill-of-material/get-edit/${id}`)
                .then((resp) => {
                    if (resp.code == 200) {
                        this.code = resp.data.code;
                        this.IDs = resp.data.id;
                        this.description = resp.data.description;
                        this.instruction = resp.data.instruction;
                        if (resp.data.attachment.length) {
                            this.fileAttachment = resp.data.attachment.map((attachment) => ({
                                id: attachment.id,
                                NameFile: attachment.file_name,
                                PathFile: attachment.file_url
                            }))
                        }

                        this.selectedPurpose = this.optionPurpose.find((el) => {
                            return el.value == resp.data.purpose;
                        });

                        this.searchSku("", 0, "parent");
                        this.itemParent = resp.data.item_parent

                        this.handleGetUnitOptions(this.itemParent.sku_code, 0, "parent")
                        // this.handleGetUnitUom(this.itemParent.sku_code, this.itemParent.unit, 0, "parent")


                        this.itemLines = resp.data.item_lines
                        console.log(this.itemLines)
                        if (resp.data.item_lines.length) {
                            resp.data.item_lines.forEach((itemLines, index) => {
                                const skuCode = itemLines.sku_code
                                const unit = itemLines.unit
                                this.searchSku("", 0, "child");
                                this.handleGetUnitOptions(skuCode, index, "child")
                                // this.handleGetUnitUom(skuCode, unit, index, "child")
                            });
                        }
                    }
                    this.$vs.loading.close();
                })
                .catch((error) => {
                    this.$vs.loading.close();
                    console.log(error);
                });
        },
        mappingAttachment() {
            this.adjustment.Attachment.map((v) => {
                this.fileAttachment.push({
                    ListID: v.ListID,
                    NameFile: v.NameFile,
                    PathFile: v.PathFile,
                });
            });
        },
        handleAttachment() {
            if (this.$refs.file.files.length > 0) {
                this.file = this.$refs.file.files;
                // this.fileAttachment = [];
                for (let i = 0; i < this.$refs.file.files.length; i++) {
                    console.log(this.file[i]);
                    // this.uploadData(i);
                    this.fileAttachment.push({
                        NameFile: this.file[i].name,
                        File: this.file[i],
                    });
                    this.newFileAttachment.push({
                        NameFile: this.file[i].name,
                        File: this.file[i],
                    });
                }
                this.handleSubmitAttachment();
                document.querySelector("#fileInput").value = "";
            } else {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select file to import.",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check",
                });
            }
        },
        handleShowAttachment(file) {
            let objectURL;
            // console.log(file)
            objectURL = file.PathFile;

            // link.download = file.name; // this name is used when the user downloads the file
            var href = objectURL;
            window.open(href, "_blank").focus();
        },
        handleGetUnitOptions(skuCode, index, type) {
            this.$http.get("api/wms/v1/item-bill-of-material/unit-options", {
                params: {
                    sku_code: skuCode,
                }
            })
                .then(resp => {
                    if (type == "child") {
                        if (resp.code == 200) {
                            this.itemLines[index].optionUnit = resp.data.option_units
                        } else {
                            this.itemLines[index].optionUnit = []
                        }
                    } else {
                        if (resp.code == 200) {
                            this.itemParent.optionUnit = resp.data.option_units
                        } else {
                            this.itemParent.optionUnit = []
                        }
                    }
                })
                .catch(error => {
                    console.log(error)
                });
        },
        handleGetUnitUom(skuCode, unit, index, type) {
            this.$http.get("api/wms/v1/item-bill-of-material/unit-uom", {
                params: {
                    sku_code: skuCode,
                    unit: unit,
                }
            })
                .then(resp => {
                    if (type == "child") {
                        if (resp.code == 200) {
                            this.itemLines[index].qty_uom = resp.data
                        } else {
                            this.itemLines[index].qty_uom = 0
                        }
                    } else {
                        if (resp.code == 200) {
                            this.itemParent.qty_uom = resp.data
                        } else {
                            this.itemParent.qty_uom = 0
                        }
                    }
                })
                .catch(error => {
                    console.log(error)
                });
        },
        searchSku(search, index, type) {
            this.$http.get("api/wms/v1/item-bill-of-material/items", {
                params: {
                    type: type,
                    search: search,
                }
            })
                .then(resp => {
                    if (type == "child") {
                        if (resp.code == 200) {
                            if (resp.data.records) {
                                this.itemLines[index].optionItem = resp.data.records
                            } else {
                                this.itemLines[index].optionItem = []
                            }
                        } else {
                            this.itemLines[index].optionItem = []
                        }
                    } else {
                        if (resp.code == 200) {
                            if (resp.data.records) {
                                this.itemParent.optionItem = resp.data.records
                            } else {
                                this.itemParent.optionItem = []
                            }
                        } else {
                            this.itemParent.optionItem = []
                        }
                    }

                })
                .catch(error => {
                    console.log(error)
                });
        },
    },
};
</script>
